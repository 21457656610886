<template>
    <v-card class="tab-overflow fill-height elevation-1" color="grey lighten-2">
        <v-list color="grey lighten-2">
            <v-list-item class="align-start justify-start text-body-2">
                <v-list-item-content class="grey--text">
                    typ
                </v-list-item-content>
                <v-list-item-content class="grey--text flex-fill">
                    jméno
                </v-list-item-content>
                <v-list-item-content v-if="!isMobile" class="grey--text justify-end">
                    číslo
                </v-list-item-content>
                <v-list-item-action>
                    <contact-button v-if="$can('create', 'EventContact')" v-bind:event-id="eventId"
                                    color='black'></contact-button>
                </v-list-item-action>
            </v-list-item>
            <contact-button v-if="$can('create', 'EventContact') && showMissingResolverAlert"
                            ref="addResolverBtn"
                            v-bind:event-id="eventId"
                            color="warning"
                            :icon-only="false"
                            :outlined="false"
                            :contact-type-id="RESOLVER_CONTACT_TYPE_ID()"
                            title="Velitel zásahu"
                            block>
                Přidat kontakt velite zásahu
            </contact-button>
            <contact v-for="(contact, key) in contacts(eventKey)" :key="key" v-bind:event-id="eventId"
                     v-bind:event="event" v-bind="contact"></contact>
            <v-divider></v-divider>
        </v-list>
    </v-card>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import contact from './Contact'
import contactButton from './ContactButton'
import {RESOLVER_CONTACT_TYPE_ID} from "@/store/modules/event/contacts";
import {appBus} from "@/main";
import {ADD_RESOLVER_CONTACT} from "@/events";

export default {
    name: 'sidebar-event-contacts',
    components: {
        contact,
        contactButton,
    },
    props: {
        eventKey: String,
        eventId: Number,
    },
    data() {
        return {
            menu: false,
        }
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
        }),
        event() {
            return this.eventById(this.eventId);
        },
        ...mapGetters('events', {
            eventById: 'byId',
            showMissingResolver: 'showMissingResolver',
        }),
        ...mapGetters('eventContacts', {
            contacts: 'getByEvent',
        }),
        ...mapGetters('events', {}),
        showMissingResolverAlert() {
            return this.showMissingResolver(this.event.uuid);
        },
    },
    methods: {
        RESOLVER_CONTACT_TYPE_ID() {
            return RESOLVER_CONTACT_TYPE_ID
        },
        add() {

        }
    },
    created() {
        appBus.$on(ADD_RESOLVER_CONTACT, () => {
            console.log(this.$refs.addResolverBtn);
            this.$refs.addResolverBtn.onClick()
        });
    },
    beforeDestroy() {
        appBus.$off(ADD_RESOLVER_CONTACT);
    }

}
</script>
