import Vue from 'vue'
import {map, includes, pickBy, orderBy, forEach} from "lodash";
import api from "../../../api/api";
import {stateMerge} from "../../object-merge";

export const RESOLVER_CONTACT_TYPE_ID = 22;

const getDefaultState = () => {
    return {
        local: {},
    }
};
const state = getDefaultState();

const getters = {
    getByEvent: (state) => {
        return (key) => {
            return orderBy(pickBy(state.local, function (item) {
                return item != null && item.eventUuid === key;
            }), ['sort'], ['asc']);
        }
    },
    resolvers: (state, getters) => (eventKey) => {
        return getters.getByEventAndTypeId(eventKey, RESOLVER_CONTACT_TYPE_ID)
    },
    getByEventAndTypeId: (state) => (eventKey, contactTypeId) => {
        return Object.values(state.local).filter(
            (item) => item.eventUuid === eventKey && item.contactTypeId === contactTypeId
        );
    },
    existInEvent: (state, getters) => {
        return (key, number) => {
            let contacts = getters.getByEvent(key);
            if (contacts) {
                return includes(map(contacts, 'number'), parseInt(number));
            }
            return false;
        }
    }
};

const actions = {
    async set({commit}, payload) {
        if (payload)
            commit("MERGE_STATE", payload)
    },
    save({dispatch}, payload) {

        if (payload.contactTypeId === undefined)
            payload.contactTypeId = 4;

        return new Promise((resolve) => {
            api.save('/v2/event-contacts', payload).then((result) => {
                let payload = {};
                payload[result.id] = result;
                dispatch('set', payload);
                dispatch('notifications/add', {type: 'success', message: 'Kontakt byl uložen'}, {root: true});
                resolve();
            });
        })
    },
    remove({commit}, id) {
        return new Promise((resolve) => {
            api.delete('/v2/event-contacts/' + id).then(() => {
                commit('remove', id);
                resolve();
            });
        })
    },
    resetState({commit}) {
        commit('resetState');
    },
};

const mutations = {
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'local', false, true)
    },
    add(state, payload) {
        Vue.set(state.local, payload.key, payload.item);
    },
    update(state, payload) {
        forEach(payload.item, (value, key) => {
            state.local[payload.key][key] = value;
        });
    },
    remove(state, key) {
        if (state.local[key] !== undefined) {
            Vue.delete(state.local, key);
        }
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
