<template>
    <div v-if="event">
        <v-dialog v-model="dialog" max-width="800" scrollable :fullscreen="isMobile" transition="dialog-top-transition"
                  content-class="view-dialog">
            <event-view v-bind:event="event" @close="close" v-if="dialog && event"></event-view>
        </v-dialog>
        <v-dialog v-model="dialogJournal" max-width="90vw" scrollable>
            <event-journal v-bind:event="event" @close="closeJournal"
                           v-if="dialogJournal && eventJournalKey"></event-journal>
        </v-dialog>
    </div>
</template>

<script>

import {appBus} from "@/main";
import EventView from "./View";
import EventJournal from "./Journal";
import {HIDE_EVENT_JOURNAL, HIDE_EVENT_VIEW, SHOW_EVENT_JOURNAL, SHOW_EVENT_VIEW} from "@/events";
import {mapState} from "vuex";

export default {
    components: {
        EventView,
        EventJournal,
    },
    created() {
        appBus.$on(SHOW_EVENT_VIEW, (event) => {
            this.open(event);
        });
        appBus.$on(HIDE_EVENT_VIEW, () => {
            this.close();
        });
        appBus.$on(SHOW_EVENT_JOURNAL, (key) => {
            this.openJournal(key);
        });
        appBus.$on(HIDE_EVENT_JOURNAL, () => {
            this.closeJournal();
        });
    },
    data() {
        return {
            dialog: false,
            event: null,
            eventDialogKey: null,
            dialogJournal: false,
            eventJournalKey: null,
        }
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
        }),
    },
    methods: {
        open(event) {
            this.event = event;
            this.dialog = true;
        },
        close() {
            this.event = null;
            this.dialog = false;
        },
        openJournal(uuid) {
            this.eventJournalKey = uuid;
            this.dialogJournal = true;
        },
        closeJournal() {
            this.dialogJournal = false;
        },
    },
    watch: {
        event: {
            handler: function (newVal) {
                if (!newVal) {
                    this.close();
                }
            },
            deep: true
        }
    },
    beforeDestroy() {
        appBus.$off(SHOW_EVENT_VIEW);
        appBus.$off(HIDE_EVENT_VIEW);
        appBus.$off(SHOW_EVENT_JOURNAL);
        appBus.$off(HIDE_EVENT_JOURNAL);
    }

}
</script>
