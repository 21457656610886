<template>
    <v-row no-gutters class="nav-buttons" :class="{'flex-grow-0': !isMobile, 'flex-grow-1': isMobile}">
        <v-divider v-if="!isMobile" class="grey" vertical></v-divider>
        <v-col>
            <div>
            <v-btn tile block dark :color="eventsTabColor" depressed ripple v-on:click="eventList" class="py-6">
                <div class="nav-new-messages pulse" v-if="newMessages">
                    <v-icon left size="26" color="white">mode_comment</v-icon>
                    <span>!</span>
                </div>
                <v-icon left>$vuetify.icons.events</v-icon>
                <span>Události </span>({{ eventCount }})
            </v-btn>
            </div>
        </v-col>
        <v-col cols="4" v-if="isMobile">
            <v-btn tile block dark :color="eventsTabColor" depressed ripple v-on:click="toggleFilter" class="py-6">
                <v-icon>filter_alt</v-icon>
            </v-btn>
        </v-col>
        <v-divider v-if="!isMobile" class="grey" vertical></v-divider>
        <v-col v-if="!isMobile && $can('view', 'Event')" >
            <v-btn tile block dark :color="calendarTabColor" depressed ripple v-on:click="calendarList" class="py-6">
                <v-icon left>$vuetify.icons.calendar</v-icon>
                <span>Kalendář</span>
            </v-btn>
        </v-col>
        <v-col :cols="isMobile ? 2 : ''" v-if="$can('create', 'Event')">
            <v-btn tile block dark color="error" depressed ripple v-on:click="newEvent" class="py-6">
                <v-icon :left="!isMobile">$vuetify.icons.newEvent</v-icon>
                <span>Příjem volání</span>
            </v-btn>
        </v-col>
        <confirm-dialog ref="confirm" type="delete"></confirm-dialog>
        <v-dialog v-if="filterDialog" v-model="filterDialog" scrollable max-width="500">
            <event-filters @onClose="toggleFilter"></event-filters>
        </v-dialog>
    </v-row>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'
import {size, isEmpty} from "lodash";
import moment from "moment";
import EventFilters from "@/components/panel/sidebar/EventFilters";

export default {
    name: 'sidebar-nav',
    components: {
        EventFilters,
        ConfirmDialog: () => import("@/components/ui/ConfirmDialog"),
    },
    data() {
        return {
            filterDialog: false,
            clicks: 0,
            timer: null,
            delay: 200,
        }
    },
    computed: {
        filterColor() {
            return isEmpty(this.$store.state.events.filters) ? 'grey' : 'yellow'
        },
        unvisitedMessages() {
            if (!this.isActiveEvent)
                return null;
            return this.unvisitedMessagesExceptActiveEvent;
        },
        ...mapGetters('events', {
            events: 'getFilteredEvents',
            activeEvent: 'getActiveEvent',
            isActiveEvent: 'isActiveEvent',
        }),
        ...mapGetters('messages', {
            unvisitedMessagesExceptActiveEvent: 'unvisitedExceptActiveEvent',
        }),
        ...mapState({
            page: state => state.panel.page,
            sidebar: state => state.panel.sidebar,
            newMessages: state => state.messages.newMessages,
            isMobile: state => state.isMobile,
        }),
        eventCount() {
            return size(this.events);
        },
        eventsTabColor() {
            return this.sidebar === 'event' ? 'grey darken-3' : 'grey darken-2';
        },
        calendarTabColor() {
            return this.sidebar === 'calendar' ? 'grey darken-3' : 'grey darken-2';
        },
    },
    methods: {
        async onSetMessagesAsRead() {
            if (await this.$refs.confirm.open('Označení všech zpráv za přečtené', 'Všechny zprávy budou označeny za přečtené.')) {
                await this.readAllMessages();
            }
        },
        eventList() {
            this.clicks++;
            if (this.clicks === 1) {
                this.timer = setTimeout(() => {
                    this.clicks = 0;
                    this.setActiveEvent(false);
                    this.$store.dispatch('panel/setSidebar', 'event');
                }, this.delay);
            } else {
                clearTimeout(this.timer);
                this.clicks = 0;
                this.onSetMessagesAsRead();
            }
        },
        calendarList() {
            this.$store.state.panel.date = moment().format(moment.HTML5_FMT.DATE);
            this.setActiveEvent(false);
            this.$store.dispatch('panel/setSidebar', 'calendar');
        },
        toggleFilter() {
            this.filterDialog = !this.filterDialog;
        },
        ...mapActions('events', {
            setActiveEvent: 'setActiveEvent',
            newEvent: 'newEvent',
        }),
        ...mapActions('messages', {
            readAllMessages: 'readAll',
        }),
    },
    watch: {
        unvisitedMessagesExceptActiveEvent(newValue, oldValue) {
            if (newValue > oldValue)
                this.$store.commit('messages/setNew', true);
        },
        isActiveEvent() {
            this.$store.commit('messages/setNew', false);
        }
    }
}
</script>

<!--<style lang="scss">-->
<!--.panel-header-sidebar {-->
<!--.v-item-group {-->
<!--width: 100%;-->
<!--}-->
<!--.v-btn-group {-->
<!--.v-btn {-->
<!--border-radius: 0 !important;-->
<!--}-->
<!--}-->
<!--}-->
<!--</style>-->
