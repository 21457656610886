const getDefaultState = () => {
    return {
        visible: false,
        search: "",
        config: {}
    }
};

const state = getDefaultState();

const getters = {
    isVisible: state => {
        return state.visible;
    },
};

const actions = {
    setSearch: ({commit}, value) => {
        commit("SET_SEARCH", value);
    },
};

const mutations = {
    SET_SEARCH: (state, value) => {
        state.search = value;
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
