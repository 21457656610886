var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"event-detail fill-sidebar grey lighten-2",attrs:{"fill-height":"","column":""}},[(_vm.tabIndex !== 'event-map')?_c('v-flex',{staticClass:"event-head-wrapper flex-grow-0",attrs:{"d-flex":""}},[(!_vm.isCallForm)?_c('event-head',{attrs:{"eventKey":_vm.activeEvent,"active":true},on:{"onSelect":function($event){return _vm.showDetail(_vm.event)}}}):_vm._e()],1):_vm._e(),(_vm.isCallForm)?_c('div',[_c('event-form',{attrs:{"isCall":_vm.isCall}})],1):_vm._e(),(_vm.event.id !== null)?_c('v-flex',{staticClass:"event-detail-content overflow-auto",attrs:{"d-flex":"","column":""}},[(_vm.visibleTabs)?_c('v-tabs',{ref:"tabs",staticClass:"event-detail-tabs",attrs:{"grow":"","hide-slider":true,"background-color":"grey lighten-2","color":"black","active-class":"grey lighten-2"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('v-tabs-slider',{attrs:{"color":"transparent"}}),_vm._l((_vm.enabledMessageCategories),function(item,index){return _c('v-tooltip',{key:index,attrs:{"top":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.isTab('chat') && _vm.$can('view', 'Message'))?_c('v-tab',_vm._g({attrs:{"active-class":item.color,"href":'#event-messages-'+item.id},on:{"click":function($event){return _vm.onClick(item.id)}}},on),[_c('v-badge',{attrs:{"value":_vm.unvisitedByEvent(_vm.event, item.id).length > 0,"offset-y":"16px","offset-x":"3px","color":item.unreadClass,"overlap":"","content":_vm.unvisitedByEvent(_vm.event, item.id).length}},[_c('v-icon',{attrs:{"size":_vm.tabIconSize}},[_vm._v(_vm._s(_vm.chatIcon(item.id)))])],1)],1):_vm._e()]}}],null,true)},[_vm._v(" "+_vm._s(item.options.tooltip)+" ")])}),_vm._l((_vm.enabledMessageCategories),function(item,index){return _c('v-tab-item',{key:index,staticClass:"event-messages-tab-content",attrs:{"transition":false,"value":'event-messages-' + item.id}},[(_vm.$can('view', 'Message'))?_c('event-messages',{attrs:{"eventId":_vm.event.id,"messageCategory":item}}):_vm._e()],1)}),(_vm.isMobile)?_c('v-tooltip',{attrs:{"top":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({attrs:{"href":"#event-map"}},on),[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("map")])],1)]}}],null,false,1465793980)},[_vm._v(" Mapa ")]):_vm._e(),(_vm.isMobile)?_c('v-tab-item',{attrs:{"transition":false,"value":"event-map","touchless":""}}):_vm._e(),_c('v-tooltip',{attrs:{"top":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.isTab('contacts') && _vm.$can('view', 'EventContact'))?_c('v-tab',_vm._g({attrs:{"href":"#event-contacts"}},on),[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("$vuetify.icons.eventContacts")])],1):_vm._e()]}}],null,false,449566604)},[_vm._v(" Telefonní seznam ")]),(_vm.$can('view', 'EventContact'))?_c('v-tab-item',{attrs:{"transition":false,"value":"event-contacts"}},[_c('event-contacts',{attrs:{"event-key":_vm.event.uuid,"event-id":_vm.event.id}})],1):_vm._e(),_c('v-tooltip',{attrs:{"top":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.isTab('event-jobs') && _vm.$can('view', 'EventResources'))?_c('v-tab',_vm._g({attrs:{"href":"#event-resources"}},on),[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("$vuetify.icons.eventResources")])],1):_vm._e()]}}],null,false,3670775187)},[_vm._v(" Technika ")]),(_vm.isTab('event-jobs') && _vm.$can('view', 'EventResources'))?_c('v-tab-item',{attrs:{"transition":false,"value":"event-resources"}},[_c('sidebar-event-jobs',{attrs:{"event":_vm.event}})],1):_vm._e(),(!_vm.isMobile)?_c('v-tooltip',{attrs:{"top":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.isTab('form'))?_c('v-tab',_vm._g({attrs:{"href":"#event-info"}},on),[_c('v-badge',{staticClass:"align-self-center",attrs:{"color":"error","offset-y":"15","bordered":"","right":"","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-icon',{attrs:{"size":"9"}},[_vm._v("$vuetify.icons.edited")])]},proxy:true}],null,true),model:{value:(_vm.isActiveEventChanged),callback:function ($$v) {_vm.isActiveEventChanged=$$v},expression:"isActiveEventChanged"}},[_c('v-icon',{attrs:{"size":_vm.tabIconSize}},[_vm._v("$vuetify.icons.eventInfo")])],1)],1):_vm._e()]}}],null,false,4289433028)},[_vm._v(" Další nastavení ")]):_vm._e(),_c('v-tab-item',{attrs:{"transition":false,"value":"event-info"}},[_c('event-detail',{attrs:{"allow-chat":false}})],1),(!_vm.isMobile)?_c('v-tooltip',{attrs:{"top":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.isTab('related'))?_c('v-tab',_vm._g({attrs:{"href":"#event-child"}},on),[_c('v-icon',[_vm._v("$vuetify.icons.eventCalls")])],1):_vm._e()]}}],null,false,887930630)},[_vm._v(" Historie akcí událostí ")]):_vm._e(),_c('v-tab-item',{attrs:{"transition":false,"value":"event-child"}},[_c('event-child-events',{ref:"eventChild",attrs:{"event":_vm.event}})],1)],2):_vm._e()],1):_vm._e(),(_vm.tabIndex === 'event-map')?_c('map-component',{ref:"map",on:{"onMapClose":function($event){_vm.tabIndex='event-contacts'}}}):_vm._e(),(!_vm.isMobile && _vm.messageCategory === 2 && _vm.$can('manage', 'Message2') && !_vm.isLocal(_vm.activeEvent))?_c('radio',{attrs:{"event-id":_vm.event.id}}):_vm._e(),(_vm.messageCategory === 2 && _vm.$can('manage', 'Message2') && !_vm.isLocal(_vm.activeEvent))?_c('capture-photo',{attrs:{"event-id":_vm.event.id}}):_vm._e(),_c('MessageForward',{attrs:{"event":_vm.event}}),_c('confirm-dialog',{ref:"confirm",attrs:{"type":"delete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }