/* eslint-disable */

import app from "../../api/app";
import merge from "lodash/merge";
import forEach from "lodash/forEach";
import {EVENT_DIALOG_RESET_STATE} from "../actions/eventDialogs";
import {appBus} from "@/main";
import {ON_APP_LOG_SEND} from "@/events";
import moment from "moment/moment";

const getDefaultState = () => {
    return {
        hash: null,
        currentVersionHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
        newVersionHash: null,
        isOnline: navigator.onLine,
        updatedAt: null,
        dataLoaded: false,
        minuteDate: Date.now(),
        secondDate: Date.now(),
    }
};

const state = getDefaultState();

// getters
const getters = {
    isPanelLoaded: (state) => {
        return state.dataLoaded;
    },
    hasVersionChanged: (state) => {
        if (!state.newVersionHash)
            return false;

        return state.newVersionHash !== state.currentVersionHash;
    }
};

// actions
const actions = {
    getData({state, commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            app.getData(payload, state.dataLoaded ? 20000 : null).then((data) => {
                if (data !== undefined) {
                    if (data.resetData === true)
                        dispatch('resetState');

                    dispatch('setData', data).then(() => {
                        commit('setHash', data.uid);
                        commit('setUpdatedAt');
                        dispatch("setPanelDefaults");
                        commit('setDataLoaded', true);
                        resolve();
                    });
                }
            }).catch(() => {
                reject('Failed to load data');
            });
        })
    },
    setPanelDefaults({state, dispatch}) {
        if (!state.dataLoaded)
            dispatch("events/setDefaultEventFilter", null, { root: true });  
    },
    async set({commit, state, dispatch}, data) {
        if (data.requestLog !== undefined) {
            appBus.$emit(ON_APP_LOG_SEND);
        }
    },
    async setData({dispatch, commit}, data) {
        try {
            await dispatch('settings/set', data.settings, {root: true});
            await dispatch('auth/setUserPermissions', data.userPermissions, {root: true});
            await dispatch('bases/set', data.bases, {root: true});
            await dispatch('priorities/set', data.priorities, {root: true});
            await dispatch('tags/set', data.tags, {root: true});
            await dispatch('eventRoles/set', data.eventRoles, {root: true});
            await dispatch('services/set', data.services, {root: true});
            await dispatch('eventTypes/set', data.eventTypes, {root: true});
            await dispatch('messageTypes/set', data.messageTemplates, {root: true});
            await dispatch('resourceTypes/set', data.resourceTypes, {root: true});
            await dispatch('contactTypes/set', data.contactTypes, {root: true});
            await dispatch('eventCategories/set', data.eventCategories, {root: true});
            await dispatch('eventTerrains/set', data.eventTerrains, {root: true});
            await dispatch('devices/set', data.devices, {root: true});
            await dispatch('events/set', {events: data.events, resetData: data.resetData}, {root: true});
            await dispatch('eventToEvent/set', data.eventToEvent, {root: true});
            await dispatch('resources/set', data.resources, {root: true});
            await dispatch('eventJobs/set', data.eventJobs, {root: true});
            await dispatch('geo/set', data.eventGeos, {root: true});
            await dispatch('eventPoises/set', data.eventObjects, {root: true});
            await dispatch('eventContacts/set', data.eventContacts, {root: true});
            await dispatch('messages/set', data.messages, {root: true});
            await dispatch('set', data);
        } catch (error) {
            window.console.error(error);
        }
    },
    async resetState({commit}, payload = null) {
        if (payload === null || payload === undefined) {
            commit('resetState', null);
            commit('settings/resetState', null, {root: true});
            commit('setup/resetState', null, {root: true});
            commit('auth/resetPermissions', null, {root: true});
            commit('priorities/RESET_STATE', null, {root: true});
            commit('tags/resetState', null, {root: true});
            commit('eventRoles/resetState', null, {root: true});
            commit('bases/resetState', null, {root: true});
            commit('events/resetState', null, {root: true});
            commit('resources/resetState', null, {root: true});
            commit('eventJobs/resetState', null, {root: true});
            commit('geo/resetState', null, {root: true});
            commit('eventPoises/resetState', null, {root: true});
            commit('eventToEvent/resetState', null, {root: true});
            commit('eventTypes/resetState', null, {root: true});
            commit('messageTypes/resetState', null, {root: true});
            commit('resourceTypes/resetState', null, {root: true});
            commit('eventCategories/resetState', null, {root: true});
            commit(EVENT_DIALOG_RESET_STATE, null, {root: true});
            commit('eventContacts/resetState', null, {root: true});
            commit('messages/resetState', null, {root: true});
            commit('devices/resetState', null, {root: true});
            commit('map/resetState', null, {root: true});
            commit('routes/resetState', null, {root: true});
            commit('notifications/resetState', null, {root: true});
            commit('panorama/resetState', null, {root: true});
            commit('cadastre/resetState', null, {root: true});
            commit('calendar/RESET_STATE', null, {root: true});
            commit('railImages/resetState', null, {root: true});
            commit('services/RESET_STATE', null, {root: true});
            commit('contactTypes/resetState', null, {root: true});
            commit('eventTerrains/resetState', null, {root: true});
            commit('sap/resetState', null, {root: true});
            commit('eventDestination/resetState', null, {root: true});
        } else {
            commit('resetState', payload);
            commit('settings/resetState', payload.settings, {root: true});
            commit('setup/resetState', payload.setup, {root: true});
            commit('auth/resetPermissions', payload.userPermissions, {root: true});
            commit('tags/resetState', payload.tags, {root: true});
            commit('priorities/RESET_STATE', payload.priorities, {root: true});
            commit('eventRoles/resetState', payload.eventRoles, {root: true});
            commit('bases/resetState', payload.bases, {root: true});
            commit('events/resetState', payload.events, {root: true});
            commit('eventToEvent/resetState', payload.eventToEvent, {root: true});
            commit('resources/resetState', payload.resources, {root: true});
            commit('eventJobs/resetState', payload.eventJobs, {root: true});
            commit('eventPoises/resetState', payload.eventObjects, {root: true});
            commit('geo/resetState', payload.geo, {root: true});
            commit('eventTypes/resetState', payload.eventTypes, {root: true});
            commit('messageTypes/resetState', payload.messageTemplates, {root: true});
            commit('resourceTypes/resetState', payload.resourceTypes, {root: true});
            commit('eventCategories/resetState', payload.eventCategories, {root: true});
            commit(EVENT_DIALOG_RESET_STATE, payload.eventDialog, {root: true});
            commit('eventContacts/resetState', payload.eventContacts, {root: true});
            commit('messages/resetState', payload.messages, {root: true});
            commit('devices/resetState', payload.devices, {root: true});
            commit('map/resetState', payload.map, {root: true});
            commit('routes/resetState', payload.routes, {root: true});
            commit('notifications/resetState', payload.notifications, {root: true});
            commit('panorama/resetState', payload.panorama, {root: true});
            commit('cadastre/resetState', payload.cadastre, {root: true});
            commit('calendar/RESET_STATE', payload.calendar, {root: true});
            commit('railImages/resetState', payload.railImages, {root: true});
            commit('services/RESET_STATE', payload.services, {root: true});
            commit('contactTypes/resetState', payload.contactTypes, {root: true});
            commit('eventTerrains/resetState', payload.eventTerrains, {root: true});
            commit('sap/resetState', payload.sap, {root: true});
            commit('eventDestination/resetState', payload.eventDestination, {root: true});
        }
    },
    async replaceState({dispatch, commit}, payload) {
        await dispatch('resetState', payload);
    }
};

// mutations
const mutations = {
    setHash(state, hash) {
        state.hash = hash;
    },
    setDataLoaded(state, value) {
        if (state.dataLoaded !== value)
            state.dataLoaded = value;
    },
    setMinuteDate(state) {
        state.minuteDate = Date.now();
    },
    setSecondDate(state) {
        state.secondDate = Date.now();
    },
    setOnlineStatus(state, status) {
        console.log("NETWORK STATUS", status);
        state.isOnline = status;
    },
    setUpdatedAt(state) {
        state.updatedAt = Date.now();
    },
    setData(state, data) {
        // eslint-disable-next-line
        window.console.log("UNUSED", data);
    },
    setVersionHash(state, value) {
        state.versionHash = value;
    },
    setNewVersionHash(state, value) {
        state.newVersionHash = value;
    },
    resetState(state, payload) {
        if (payload) {
            Object.assign(state, payload);
        } else {
            Object.assign(state, getDefaultState());
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
