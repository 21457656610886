/* eslint-disable */
import Vue from 'vue'
import includes from "lodash/includes";
import forEach from "lodash/forEach";
import pick from "lodash/pick";
import findKey from "lodash/findKey";
import toNumber from "lodash/toNumber";
import {stateMerge} from "../object-merge";

const getDefaultState = () => {
    return {
        items: {},
    }
};

const state = getDefaultState();

const getters = {
    options: state => {
        let options = [];
        forEach(state.items, (item, id) => {
            options.push({
                value: Number(id),
                text: item.name,
            });
        });
        return options;
    },
};

const actions = {
    async set({commit, state, dispatch}, items) {
        if (items)
            commit("MERGE_STATE", items)
    },
};

const mutations = {
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'items', false, true)
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
