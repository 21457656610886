<template>
    <div class="event-head" :class="active ? 'grey lighten-3' : null">
        <v-list-item v-ripple @click="onSelect" class="event-head-info">
            <v-list-item-action class="icon justify-start">
                <v-badge offset-y="17" bordered left overlap :value="isEventChanged(event.uuid)">
                    <template v-slot:badge>
                        <v-icon size="9">$vuetify.icons.edited</v-icon>
                    </template>
                    <v-icon size="40" :color="iconColor">{{ eventCategory.icon }}</v-icon>
                    <v-tooltip left v-if="muted">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="muted" color="white" size="16">volume_off</v-icon>
                        </template>
                        Zvuková upozornění na zprávy jsou vypnuta
                    </v-tooltip>
                </v-badge>
                <span class="date">{{ smartDate }}</span>
            </v-list-item-action>
            <v-list-item-content class="py-2">
                <v-list-item-title class="d-flex">
                    <span v-show="active === false">
                        <v-chip v-for="(item, i) in unvisitedMessagesByMessageCategories"
                                :color="item.messageCategory.unreadClass" :key="i" small class="message-count">
                            <div>{{ item.unvisited }}</div>
                        </v-chip>
                    </span>
                    <div :class="getTitleClass" class="text-truncate">{{ getTitle }}</div>
                    <event-tags v-bind:event="event"></event-tags>
                </v-list-item-title>
                <v-list-item-subtitle>
                    <div :class="getSubtitleClass" linkified v-html="getSubtitle"></div>
                    <div :class="getDescriptionClass" linkified v-html="getDescription"></div>
                    <div class="my-1" v-for="(contact, id) in eventResolvers" :key="id">Řešitel: <strong
                            class="black--text">{{ contact.name }}</strong></div>
                    <div v-if="active" class="overview" :class="getOverviewClass" v-html="getOverview"></div>
                    <v-chip dense color="warning" class="mt-1" v-if="showMissingResolverAlert" @click.stop="addResolver">Chybí
                        kontakt na velitele zásahu
                    </v-chip>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <linkedEventsHeadBar :event="event"/>
        <v-list-item v-if="isJobsBar" class="pnt">
            <v-list-item-action class="pnt-eta body-2 text-center" v-if="isEta">
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-sheet v-on="on" class="pnt-eta-box" width="60" height="42" outlined>
                            {{ etaDuration }}<br/><small>{{ eta }}</small>
                        </v-sheet>
                    </template>
                    Očekávaná doba dojezdu prvního<br/>vozidla na místo události.
                </v-tooltip>
            </v-list-item-action>
            <v-list-item-content :class="!isEta ? 'ml-15 pnt-jobs': 'pnt-jobs'">
                <v-container fluid grid-list-sm class="pa-0">
                    <v-layout row wrap>
                        <v-flex v-for="(job, i) in visibleJobs" :key="i" xs3>
                            <job v-bind:job="job" v-bind:active="active"></job>
                        </v-flex>
                        <v-flex v-if="jobs.length > jobsVisible" xs3>
                            <div class="event-job event-job-more" @click="showResourceTab">
                                +{{ moreJobsCount }} <br/>
                                <small>zobrazit</small>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-list-item-content>
        </v-list-item>
        <slot></slot>
        <v-divider class="grey darken-1"></v-divider>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import Job from './Job';
import EventTags from './EventTags';
import {forEach} from "lodash";
import {faCommentAlt as messages,} from '@fortawesome/free-solid-svg-icons'
import moment from "moment/moment";
import {formatDuration} from "@/helpers/duration";
import {appBus} from "@/main";
import {ADD_RESOLVER_CONTACT, SELECT_EVENT_TAB} from "@/events";
import LinkedEventsHeadBar from "@/components/panel/sidebar/event/LinkedEventsHeadBar.vue";
import {smartDate} from "@/helpers/format";

export default {
    name: 'event-head',
    data() {
        return {
            icons: {
                messages,
            },
        }
    },
    components: {
        LinkedEventsHeadBar,
        Job,
        EventTags
    },
    props: {
        eventKey: String,
        active: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        smartDate() {
            return smartDate(this.event.start)
        },
        iconColor() {
            if (this.event.priorityId) {
                const priority = this.$store.getters['priorities/getById'](this.event.priorityId)
                return priority?.color || this.eventCategory?.marker.color
            }
            return this.eventCategory.color
        },
        jobsVisible() {
            return this.isMobile ? 4 : 4;
        },
        moreJobsCount() {
            return this.jobs.length - this.jobsVisible + 1;
        },
        visibleJobs() {
            if (this.jobs.length <= this.jobsVisible) {
                return this.jobs.slice(0, this.jobsVisible);
            } else {
                return this.jobs.slice(0, this.jobsVisible - 1);
            }
        },
        unvisitedMessagesByMessageCategories() {
            return this.unvisitedByEventGroupByMessageCategory(this.event, this.enabledMessageCategories)
        },
        enabledMessageCategories() { // todo: rewrite it to getter
            let items = {};
            forEach(this.eventCategory.messageCategoryIds, (item, index) => {
                if (this.$can('manage', 'Message' + index))
                    items[parseInt(index)] = this.messageCategories[parseInt(index)];
            });
            return items;
        },
        isJobsBar() {
            return this.isEta || this.showJobs
        },
        ...mapState({
            minuteDate: store => store.app.minuteDate,
            messageCategories: state => state.messages.categories,
        }),
        muted() {
            return this.$store.getters['panel/isEventMuted'](this.event.uuid);
        },
        isEta() {
            return this.eta;
        },
        eta() {
            if (this.event.eta) {
                return moment(this.event.eta).format("HH:mm");
            } else if (this.event.etaDuration) {
                return moment(this.minuteDate).add(this.event.etaDuration, "seconds").format("HH:mm");
            }
            return null;
        },
        etaDuration() {
            let duration = null;
            if (this.event.eta) {
                let startTime = moment(this.minuteDate);
                let endTime = moment(this.event.eta);
                duration = formatDuration(moment.duration(endTime.diff(startTime)).asMilliseconds());
            } else if (this.event.etaDuration) {
                duration = formatDuration(this.event.etaDuration * 1000);
            }
            return duration;
        },
        unvisitedMessagesCount() {
            return this.unvisitedMessages.length;
        },
        unvisitedMessages() {
            return this.unvisitedByEvent(this.event);
        },
        showJobs() {
            return this.jobs.length > 0
        },
        jobs() {
            return this.$store.getters['eventJobs/jobs'](this.eventKey)
        },
        getTitle() {
            if (this.event.id === null)
                return this.eventCategory.name;

            return this.event.header && this.event.header.title.value !== undefined ? this.event.header.title.value : null;
        },
        getSubtitle() {
            return this.event.header && this.event.header.subtitle.value !== undefined ? this.event.header.subtitle.value : null;
        },
        getDescription() {
            return this.event.header && this.event.header.description.value !== undefined ? this.event.header.description.value : null;
        },
        getOverview() {
            return this.event.header && this.event.header.overview !== undefined && this.event.header.overview.value !== undefined ? this.event.header.overview.value : null;
        },
        getTitleClass() {
            return this.event.header && this.event.header.title.class !== undefined ? this.event.header.title.class : null;
        },
        getSubtitleClass() {
            return this.event.header && this.event.header.subtitle.class !== undefined ? this.event.header.subtitle.class + ' text-truncate' : null;
        },
        getDescriptionClass() {
            return this.event.header && this.event.header.description.class !== undefined ? this.event.header.description.class + ' text-truncate' : null;
        },
        getOverviewClass() {
            return this.event.header && this.event.header.overview !== undefined && this.event.header.overview.class !== undefined ? this.event.header.overview.class : null;
        },
        ...mapGetters('events', {
            isEventChanged: 'isEventChanged',
            showMissingResolver: 'showMissingResolver',
        }),
        ...mapGetters('messages', {
            unvisitedByEvent: 'unvisitedByEvent',
            unvisitedByEventGroupByMessageCategory: 'unvisitedByEventGroupByMessageCategory',
        }),
        showMissingResolverAlert() {
            return this.showMissingResolver(this.event.uuid);
        },
        eventCategory() {
            return this.getEventCategoryById(this.event.eventCategoryId);
        },
        ...mapGetters('eventTypes', {
            getEventType: 'get',
        }),
        ...mapGetters('eventCategories', {
            getEventCategoryById: 'getEventCategoryById',
        }),
        ...mapGetters('eventContacts', {
            resolvers: 'resolvers',
        }),
        eventResolvers() {
            return this.resolvers(this.event.uuid);
        },
        ...mapState({
            event(state) {
                return state.events.local[this.eventKey]
            },
            isMobile: state => state.isMobile,
            activeEventKey: state => state.events.active,
        }),
    },
    methods: {
        addResolver() {
            if (this.event.uuid !== this.activeEventKey) {
                this.$store.dispatch('events/setActiveEvent', this.event.uuid).then(() => {
                    if (this.$can('create', 'EventContact')) {
                        this.$nextTick(() => {
                            appBus.$emit(SELECT_EVENT_TAB, 'event-contacts');
                            this.$nextTick(() => {
                                setTimeout(() => appBus.$emit(ADD_RESOLVER_CONTACT), 250);
                            });
                        });
                    }
                });
            } else {
                appBus.$emit(ADD_RESOLVER_CONTACT)
            }
        },
        showResourceTab() {
            this.$store.dispatch('events/setActiveEvent', this.event.uuid).then(() => {
                appBus.$emit(SELECT_EVENT_TAB, 'event-resources');
            });
        },
        onSelect() {
            this.$emit('onSelect', this.event.uuid);
        },
    }
}
</script>

