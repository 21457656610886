<template>
    <v-card v-if="visible" :loading="status===1" class="cadastre-wrapper">
        <v-toolbar
                color="default"
                dark
                dense
        >
            <v-toolbar-title>
                <small>
                    <span v-show="status===1">
                    Načítám... |
                </span>
                    Náhled do katastru nemovitostí
                </small>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="pl-3">
                <v-btn fab x-small v-show="status===5">
                    <v-icon v-show="link" @click="openInNewWindow">open_in_new</v-icon>
                </v-btn>
                <v-btn fab x-small @click="toggleMapVariant" v-show="status===5">
                    <v-icon>invert_colors</v-icon>
                </v-btn>
                <v-btn fab x-small @click="toggleSize" v-show="status===5">
                    <v-icon>{{ resizeIcon }}</v-icon>
                </v-btn>
                <capture-screen-button v-if="$can('update', 'Event')" :el="$refs.print" :message-type="messageType"></capture-screen-button>
                <v-btn fab x-small color="error" @click="close">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card v-show="maximized" ref="print">
            <v-card-text>
                <div class="content" v-html="content"></div>
            </v-card-text>
        </v-card>
    </v-card>
</template>

<script>

    import {mapActions, mapGetters, mapState} from "vuex";
    import cadastreApi from "../../../api/cadastre";
    import {isEmpty} from "lodash";
    import {appBus} from "@/main";

    export default {
        name: 'map-cadastre',
        data() {
            return {
                status: 0,
                maximized: true,
                cancelSource: null,
                link: null,
                content: null,
                messageType: 'X25',
            }
        },
      components: {
        CaptureScreenButton: () => import("@/components/ui/CaptureScreenButton"),
      },
        computed: {
            ...mapState({
                coordinates: state => state.cadastre.coordinates,
            }),
            ...mapGetters('cadastre', [
                'visible',
            ]),
            resizeIcon() {
                return this.maximized ? 'minimize' : 'maximize';
            },
            coordinates: {
                get() {
                    return this.$store.state.cadastre.coordinates;
                },
                set(value) {
                    this.$store.commit('cadastre/setCoordinates', value)
                }
            },
        },
        methods: {
            ...mapActions('cadastre', {
                closeCadastre: 'close',
            }),
            toggleSize() {
                this.maximized = !this.maximized;
            },
            close() {
                this.status = 0;
                this.closeCadastre();
            },
            getData(coordinates) {
                this.status = 1;
                this.maximized = false;
                return cadastreApi.getData(coordinates).then((response) => {
                    if (this.visible) {
                        this.link = response.link;
                        this.content = response.content;
                    }
                }).catch(() => {
                    this.content = 'Chyba při načítání dat z Katastru nemovitostí. Opakujte akci později';
                    this.link = null;
                }).finally(() => {
                    this.status = 5;
                    this.maximized = true;
                });
            },
            openInNewWindow() {
                if (this.link)
                    window.open(this.link)
            },
            toggleMapVariant() {
                appBus.$emit('toggleLayers', {toggle: ['kn', 'kni']});
            }
        },
        watch: {
            coordinates: function (coordinates) {
                if (!isEmpty(coordinates))
                    this.getData(coordinates);
            },
            status: function (newStatus, oldStatus) {
                if (oldStatus === 0)
                    appBus.$emit('toggleLayers', {show: ['kn']});

                if (newStatus === 0)
                    appBus.$emit('toggleLayers', {hide: ['kn', 'kni']});
            }
        },
    }
</script>
