<template>
    <v-card raised dark width="520" v-if="data && data.props.id > 0" color="grey darken-3" :loading="processing"
            :disabled="processing" max-height="90vh" class="overflow-y-auto">
        <v-container>
            <div class="d-flex pb-1">
                <div class="caption flex-grow-1" v-if="data.props.message">
                    {{ data.props.message.createdAt | moment('DD.MM. HH:mm:ss') }} ->
                    {{ compareAndFormatDates(data.props.message.createdAt, data.props.message.insertedAt) }}
                </div>
                <v-btn v-clipboard:copy="data.props.message.latitude + ', ' + data.props.message.longitude"
                       v-clipboard:success="onCopy"
                       v-clipboard:error="onError" text small
                       v-if="data.props.message.latitude && data.props.message.longitude">
                    <v-icon left>near_me</v-icon>
                    {{ data.props.message.latitude }}, {{ data.props.message.longitude }}
                </v-btn>
                <debug-button :data="data.props.message"></debug-button>
                <v-btn @click="close" small icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </div>
            <v-card class="messages pa-5" light :class="this.data.props.category.color">
                    <message
                            v-bind="data.props"
                            v-bind:single="true"
                            v-bind:messages="[]"
                            v-bind:unvisited="[]"
                            v-bind:previous="{}"
                            v-bind:next="{}"
                            v-bind:isNew="false"
                            v-bind:read="1"
                    />
                <div v-if="speechToText" class="text-right pt-3">
                <v-btn
                      v-clipboard:copy="speechToText"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      text>
                    zkopírovat přepis
                </v-btn>
                </div>
            </v-card>
            <v-row>
                <v-col>
                    <message-tags :message="data.props.message" @onProcessing="onProcessing"></message-tags>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>

import {appBus} from "@/main";
import {mapActions} from "vuex";
import {ON_CONTEXT_CLOSE} from "@/events";
import Message from '@/components/panel/sidebar/event/Message'
import MessageTags from '@/components/panel/sidebar/event/MessageTags'
import DebugButton from "@/components/ui/DebugButton";
import moment from "moment/moment";

export default {
    name: 'context-menu',
    data() {
        return {
            processing: false,
            debugPanel: [],
        }
    },
    created() {
        this.markAsRead();
    },
    props: {
        data: Object,
    },
    computed: {
        speechToText() {
            const data = this.data.props.message?.data
            if (data?.stt) {
                    return data.stt.text;
            }
            return false;
        }
    },
    components: {
        DebugButton,
        Message,
        MessageTags,
    },
    methods: {
        compareAndFormatDates(firstDate, secondDate) {
            if (moment(firstDate).isSame(secondDate, 'day')) {
                return moment(secondDate).format('HH:mm:ss');
            } else {
                return moment(secondDate).format('DD.MM. HH:mm:ss');
            }
        },
        onProcessing(state) {
            this.processing = state;
            if (state === false)
                this.close();
        },
        close() {
            appBus.$emit(ON_CONTEXT_CLOSE);
        },
        ...mapActions('messages',
            {
                readMessage: 'read',
            }
        ),
        markAsRead() {
            this.readMessage(this.data?.props?.id);
        },
        onCopy() {
            this.$noty.success("Zkopírováno do paměti");
        },
        onError() {
            this.$noty.warning("Chyba pri kopírování do paměti");
        }
    },
    watch: {
        'data.props.id': function (newValue, oldValue) {
            if (newValue !== oldValue && newValue > 0)
                this.markAsRead();
        }
    }
}

</script>
