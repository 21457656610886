/* eslint-disable */
import Vue from 'vue'
import includes from "lodash/includes";
import forEach from "lodash/forEach";
import orderBy from "lodash/orderBy";
import {stateMerge} from "../object-merge";

const getDefaultState = () => {
    return {
        items: {},
    }
};

const state = getDefaultState();

const getters = {
    getById: (state) => {
        return (id) => {
            return state.items[id];
        }
    },
    optionsByEventCategoryId: (state) => (eventCategoryId) => {
        let options = [];
        forEach(state.items, (item, id) => {
            if (includes(item.eventCategoryIds, eventCategoryId)) {
                options.push({
                    value: Number(id),
                    text: item.name,
                    sort: item.sort,
                    icon: item.icon,
                    color: item.color,
                });
            }
        });
        return orderBy(options, ['sort'], ['asc']);
    },
    options: state => {
        let options = [];
        forEach(state.items, (item, id) => {
            options.push({
                value: Number(id),
                text: item.name,
            });
        });
        return orderBy(options, ['sort'], ['asc']);
    },
};

const actions = {
    async set({commit, state, dispatch}, items) {
        if (items)
            commit("MERGE_STATE", items)
    },
};

const mutations = {
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'items', false, true)
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
