<template>
    <v-card class="event-view" :loading="loading" :disabled="loading" flat>
        <v-toolbar class="event-view-tabs-header">
            <v-sheet dark elevation="0" class="d-flex pa-3">
                <v-toolbar-title>{{ categoryName }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items class="pl-3">
                    <v-btn fab x-small color="red" @click="$emit('close')">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-sheet>
            <event-head v-bind:event="event" v-bind:active="true" v-bind:list="false" :link="false"></event-head>
            <v-tabs v-model="activeTab" class="event-view-tabs" background-color="grey lighten-3" color="black"
                    active-class="white">
                <v-tab key="main" ripple>
                    <v-icon size="37">home</v-icon>
                </v-tab>
                <v-tab v-for="eventToEvent in eventToEvents" :key="eventToEvent.id">
                    <linked-event-icon :event="eventToEvent.sourceEvent" :size="30" grayscale></linked-event-icon>
                </v-tab>
                <v-tab key="chat" ripple v-if="showHistoryChat">
                    <v-icon size="30">chat</v-icon>
                </v-tab>
            </v-tabs>
        </v-toolbar>
        <v-card-text class="pa-0">
            <v-tabs-items v-model="activeTab" :transition="false">
                <v-tab-item key="maim">
                    <v-card class="pt-2">
                        <div v-if="event.overview">
                            <div v-if="isMarkdown(event.overview)" v-html="renderMarkdown(event.overview)"></div>
                            <v-simple-table v-else dense>
                                <template v-slot:default>
                                    <tbody>
                                    <tr v-for="(row, index) in getTableData()" :key="index">
                                        <td v-for="(cell, cellIndex) in row.cells" :key="cellIndex"
                                            v-linkified
                                            v-html="cell.content"
                                            :colspan="cell.colspan"></td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                        <div v-if="event.detail">
                            <strong>Podrobný obsah:</strong><br/>
                            <div v-html="event.detail"></div>
                        </div>
                        <v-card-actions>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" dark :color="muted?'success':'error'" @click="toggleMute">
                                        <v-icon>{{ muted ? 'volume_up' : 'volume_off' }}</v-icon>
                                    </v-btn>
                                </template>
                                {{ mutedText }}
                            </v-tooltip>
                            &nbsp;
                            <confirm-button
                                    text
                                    block
                                    v-if="canRemoveParentId"
                                    v-bind:options="{
                        tooltip: 'Zrušení vazby k nadřízené události. Po provedení bude nutno událost znovu odbavit.',
                        confirm: 'Zrušit vazbu k nadřízené události?',
                        icon: 'link_off',
                        class: 'error',
                    }"
                                    @click="removeParentId">
                                Zrušit připojení
                            </confirm-button>
                            <v-spacer></v-spacer>
                            <v-btn v-if="category.code ==='KOPIS'" color="warning" @click="createTestCall">Použít pro
                                simulaci
                            </v-btn>
                            <v-btn plain @click="showAlert">
                                <v-icon>notifications</v-icon>
                            </v-btn>
                            <v-btn plain @click="showEventJournal" v-if="$can('view', 'Debug')">
                                <v-icon>code</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item v-for="eventToEvent in eventToEvents" :key="eventToEvent.id">
                    <event-view-event-to-event v-bind:eventToEvent="eventToEvent"></event-view-event-to-event>
                </v-tab-item>
                <v-tab-item key="chat" v-if="showHistoryChat">
                    <v-card class="py-2 px-4">
                            <api-data-loader :api-path="'/v2/messages/event/' + event.id">
                                <template #item="{ item }">
                                    <div class="v-message">
                                        <div class="w100 d-flex justify-space-between pb-1">
                                            <span>#{{ item.sequence }}. <strong>{{ item.senderName }}</strong></span>
                                            <span class="grey--text text--darken-1">vytvořeno: {{ item.createdAt | moment("DD.MM.YYYY HH:mm:ss")}}</span>
                                        </div>
                                        {{ item.content }}
                                        <div v-if="item.media" class="d-flex">
                                            <media v-for="(media, index) in item.media"
                                                   :id="item.id"
                                                   :key="index"
                                                   :eventId="item.eventId"
                                                   :messageId="item.id"
                                                   :media="media"
                                                   :message-category-id="item.messageCategoryId"
                                                   :messageType="item.type"
                                            />
                                        </div>
                                      <div v-if="hasStt(item)">
                                         {{ item.data.stt.text }}
                                      </div>
                                    </div>
                                    <v-divider></v-divider>
                                </template>
                            </api-data-loader>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>

<script>

import {appBus} from "@/main";
import eventHead from './HeadInactive';
import {includes} from "lodash";
import {mapActions, mapGetters} from "vuex";
import {ON_EVENT_ALERT_INSERT, SHOW_EVENT_JOURNAL, UPDATE_CHILD_EVENTS} from "@/events";
import ConfirmButton from "@/components/ui/ConfirmButton";
import EventViewEventToEvent from "@/components/panel/sidebar/event/ViewEventToEvent";
import {renderMarkdown, isMarkdown} from "@/helpers/markdown";
import LinkedEventIcon from "@/components/panel/sidebar/event/LinkedEventIcon.vue";
import ApiDataLoader from '@/components/ui/ApiDataLoader.vue';
import Media from "@/components/panel/sidebar/event/Media.vue";
import {dzinCategoryIds} from "@/store/modules/events";

export default {
    name: 'event-view',
    components: {
        Media,
        LinkedEventIcon,
        EventViewEventToEvent,
        ConfirmButton,
        eventHead,
        ApiDataLoader,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            activeTab: null,
            loading: false,
        }
    },
    computed: {
        showHistoryChat() {
            return includes(dzinCategoryIds, this.event.eventCategoryId)
        },
        ...mapGetters('eventCategories', {
            getEventCategoryById: 'getEventCategoryById',
            getSectionTitle: 'sectionTitle',
        }),
        ...mapGetters('eventToEvent', {
            byTargetEventId: 'byTargetEventId',
        }),
        ...mapGetters('services', {
            getServiceById: 'getById',
        }),
        ...mapGetters('bases', {
            baseById: 'byId',
        }),
        ...mapGetters('panel', {
            isEventMuted: 'isEventMuted',
        }),
        eventToEvents() {
            return this.byTargetEventId(this.event.id);
        },
        category() {
            return this.getEventCategoryById(this.event.eventCategoryId);
        },
        categoryName() {
            return this.category !== undefined ? this.category.name : '-';
        },
        serviceName() {
            let item = this.getServiceById(this.event.serviceId);
            return item !== undefined ? item.name : '-';
        },
        muted() {
            return this.isEventMuted(this.event.uuid);
        },
        mutedText() {
            return this.muted ? 'Zapnout přehrávání zpráv' : 'Vypnout přehrávání zpráv'
        },
        canRemoveParentId() {
            return this.event.parentId && this.category.type === 'call' && (this.category.code === 'DZIN-CALL') // this.category.code === 'UCKOPIS' ||
        }
    },
    methods: {
        renderMarkdown,
        isMarkdown,
        hasStt(item) {
            return  !!item.data?.stt;
        },
        showAlert() {
            appBus.$emit(ON_EVENT_ALERT_INSERT, this.event);
            this.$emit('close');
        },
        ...mapActions('events', {
            saveEvent: 'save',
        }),
        getTableData() {
            return this.parseHtmlTable();
        },
      speechToText(data) {
        if (data) {
          const data = JSON.parse(data);
          if (data?.stt) {
            return data.stt.text;
          }
        }
        return false;
      },
        parseHtmlTable() {
            let tableRows = [];
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.event.overview, 'text/html');
            const table = doc.querySelector('table');
            const rows = table.querySelectorAll('tr');

            rows.forEach((row) => {
                const cells = row.querySelectorAll('td');
                const rowData = {
                    cells: [],
                };

                cells.forEach((cell) => {
                    const colspan = cell.getAttribute('colspan');
                    const cellData = {
                        content: cell.innerHTML,
                        colspan: parseInt(colspan) || 1,
                    };
                    rowData.cells.push(cellData);
                });

                tableRows.push(rowData);
            });

            return tableRows;
        },
        sectionTitle(inputKey, defaultValue = null) {
            let title = this.getSectionTitle(this.category, inputKey);
            return title ? title : defaultValue;
        },
        showEventJournal() {
            appBus.$emit(SHOW_EVENT_JOURNAL, this.event.uuid);
        },
        createTestCall() {
            this.loading = true;
            this.$store.dispatch('events/createTestCall', this.event.code).then(() => {
                this.$emit('close');
                this.$store.dispatch('panel/setSidebar', 'event');
            }).finally(() => {
                this.loading = false;
            });
        },
        removeParentId() {
            let event = Object.assign({}, this.event);
            event.eventStateId = 1;
            event.parentId = null;

            this.saveEvent({event: event}).finally(() => {
                this.submitting = false;
                this.$noty.success("Odpojeno");
                appBus.$emit(UPDATE_CHILD_EVENTS);
                this.$emit('close');
            });
        },
        toggleMute() {
            this.$store.commit('panel/toggleEventMute', this.event.uuid);
            this.$emit('close')
        },
    },
}
</script>
<style>
.event-view-tabs-header {
    height: auto !important;
    padding: 0 !important;
    flex-grow: 0 !important;
}

.event-view-tabs-header .v-toolbar__content {
    padding: 0;
    display: block !important;
    height: auto !important;
}

.event-view-tabs-header .event-view-tabs {
    height: 55px !important;
}

</style>