<template>
    <v-card dark :loading="loading" :disabled="loading">
        <v-list>
            <v-list-item>
                <v-list-item-avatar>
                    <div>
                        <v-icon>person</v-icon>
                    </div>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-subtitle>{{ prefix }}</v-list-item-subtitle>
                    <v-list-item-title>{{ name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ suffix }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-btn icon @click="close()">
                        <v-icon>cancel</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-form ref="form" class="pa-3">
            <div v-if="contactTypeId && contactTypeId === RESOLVER_CONTACT_TYPE_ID()" class="text-center mb-4">
                {{ title }}
            </div>
            <contact-combobox
                hide-no-data
                outlined
                prependInnerIcon="person"
                placeholder="Jméno"
                v-model="form.name"
                v-bind:attribute="'name'"
                class="autoItemHeight mb-1"
                @choose="setNumber"
                clearable
                :error-messages="nameErrors"
                :disabled="!canChangeContent"
                v-bind:autofocus="true"
            >
            </contact-combobox>

            <contact-combobox
                hide-no-data
                outlined
                prependInnerIcon="phone"
                placeholder="Číslo"
                v-model="form.number"
                v-bind:attribute="'number'"
                class="autoItemHeight mb-1"
                @choose="setName"
                clearable
                :error-messages="numberErrors"
                :disabled="!canChangeContent"
            >
            </contact-combobox>
        </v-form>
        <v-card-actions>
          <v-btn text @click="close()">Zavřít</v-btn>
          <v-btn v-if="canSms" @click="sendSms">Zaslat SMS</v-btn>
          <div class="flex-grow-1"></div>
          <v-btn color="error" v-if="canDelete" @click="remove">Smazat</v-btn>
          <v-btn color="success" v-if="canUpdate" @click="save">Uložit</v-btn>
          <v-btn color="success" v-if="canInsert" @click="save">Vytvořit</v-btn>
        </v-card-actions>
      <sms-form v-if="form.number" v-bind:event-id="eventId" v-bind:number="form.number" v-bind:name="form.name" ref="smsForm"></sms-form>
    </v-card>
</template>

<script>

import contactCombobox from './ContactCombobox'
import smsForm from './SmsForm'
import {validationMixin} from "vuelidate";
import {required, integer, minLength, maxLength} from "vuelidate/lib/validators";
import {RESOLVER_CONTACT_TYPE_ID} from "@/store/modules/event/contacts";

export default {
    name: 'sidebar-event-contact-form',
    mixins: [validationMixin],
    components: {
        contactCombobox,
      smsForm,
    },
    mounted() {
        this.form = {
            id: this.id,
            eventId: this.eventId,
            contactTypeId: this.contactTypeId,
            title: this.title,
            name: this.name,
            number: this.number ? this.number.toString() : '',
            custom: this.id ? this.custom : 1,
        }
    },
    data() {
        return {
            loading: false,
            form: {},
            message: false,
            hints: true,
        }
    },
    props: {
        id: [Number, null],
        eventId: {
            type: Number,
            required: true,
        },
        contactTypeId: Number,
        contactTypeName: String,
        deviceCode: {
            type: String,
            default: '',
        },
        deviceId: Number,
        prefix: String,
        title: {
            type: [String, null],
            default: null,
        },
        name: {
            type: String,
            default: '&nbsp;',
        },
        suffix: String,
        number: {
            type: [Number, String],
            default: '',
        },
        code: String,
        type: String,
        icon: String,
        custom: Number,
        persistent: Number,
    },
    validations: {
        form: {
            name: {
                required,
            },
            number: {
                required,
                integer,
                minLength: minLength(9),
                maxLength: maxLength(9)
            }
        }
    },
    computed: {
        canChangeContent() {
            return this.custom === 1
        },
        canSms() {
          return this.form.id && this.form.number && (this.form.number.charAt(0) === '6' ||this.form.number.charAt(0) === '7');
        },
        canUpdate() {
            return this.id > 0 && this.canChangeContent;
        },
        canInsert() {
            return this.id === null;
        },
        canDelete() {
            return this.id > 0 && this.persistent === 0 && this.custom === 1;
        },
        nameErrors() {
            const errors = []
            if (!this.$v.form.name.$dirty) return errors
            !this.$v.form.name.required && errors.push('Prosím vyplňte')
            return errors
        },
        numberErrors() {
            const errors = []
            if (!this.$v.form.number.$dirty) return errors
            !this.$v.form.number.required && errors.push('Prosím vyplňte')
            !this.$v.form.number.integer && errors.push('Toto pole musí obsahovat jen číslice')
            !this.$v.form.number.minLength && errors.push('Vložte devítimístné telefonní číslo')
            !this.$v.form.number.maxLength && errors.push('Vložte devítimístné telefonní číslo')
            return errors
        },
    },
    methods: {
        RESOLVER_CONTACT_TYPE_ID() {
            return RESOLVER_CONTACT_TYPE_ID
        },
        validate() {
            this.$v.form.$touch();
            if (this.$v.$invalid) {
                this.submitting = false;
                return false;
            }
            return true;
        },
        close() {
            this.$emit('onClose');
        },
        remove() {
            this.loading = true;
            this.$store.dispatch('eventContacts/remove', this.id).then(() => {
                this.close();
                this.loading = false;
            });
        },
        save() {
            this.loading = true;

            if (!this.validate()) {
                this.loading = false;
                return;
            }

            if (this.form.contactTypeId === undefined)
                this.form.contactTypeId = '';

            this.$store.dispatch('eventContacts/save', this.form).then(() => {
                this.close();
                this.loading = false;
            });
        },
        setName(item) {
            this.form.name = item.name;
        },
        setNumber(item) {
            this.form.number = item.number;
        },
        sendSms() {
          this.$refs.smsForm.open();
        },
    }
}
</script>
