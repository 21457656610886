<template>
  <div class="api-data-loader">
      <div v-for="(item, key) in items" :key="key">
         <slot name="item" :item="item" />
      </div>

      <v-card v-if="isLoading" flat class="d-flex justify-center">
        <v-progress-circular indeterminate color="primary" />
      </v-card>

      <template v-if="Object.keys(items).length === 0 && !isLoading">
        <v-card flat class="d-flex justify-center">
          <v-card-title class="text-center">Žádné záznamy k zobrazení</v-card-title>
        </v-card>
      </template>
      <div ref="sentinel" class="text-center" v-if="hasMore" style="height: 60px">
          Načítám dalsí záznamy...
      </div>
  </div>
</template>

<script>
import api from '@/api/api';

export default {
  name: 'app-data-loader',
  props: {
    apiPath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: {},
      isLoading: false,
      hasMore: true,
      nextUrl: null,
      perPage: null,
      currentPage: 1,
      totalCount: null,
    };
  },
  async mounted() {
    await this.loadData(this.apiPath);
    this.initIntersectionObserver();
  },
  methods: {
    async loadData(url) {
      try {
        this.isLoading = true;

        const response = await api.getWithHeaders(url);
        const { headers, data } = response;

        this.items = { ...this.items, ...data };

        this.currentPage = parseInt(headers['x-pagination-current-page']) || this.currentPage;
        this.perPage = parseInt(headers['x-pagination-per-page']) || this.perPage;
        this.totalCount = parseInt(headers['x-pagination-total-count']) || this.totalCount;

        const linkHeader = headers['link'];
        this.nextUrl = this.parseNextLink(linkHeader);

        this.hasMore = !!this.nextUrl;
      } catch (error) {
        console.error('Chyba při načítání dat z API:', error);
        this.hasMore = false;
      } finally {
        this.isLoading = false;
      }
    },

    loadMore() {
      if (this.nextUrl && !this.isLoading) {
        this.loadData(this.nextUrl);
      }
    },

    parseNextLink(linkHeader) {
      if (!linkHeader) return null;

      const links = linkHeader.split(',').map((part) => part.trim());
      const nextLink = links.find((link) => link.includes('rel=next'));

      if (nextLink) {
        const urlMatch = nextLink.match(/<(.+)>/);
        return urlMatch ? urlMatch[1].replace(process.env.VUE_APP_TEREZA_API_URL, '') : null;
      }

      return null;
    },

      initIntersectionObserver() {
          const options = {
              root: null,
              rootMargin: '0px',
              threshold: 0.1,
          };

          this.observer = new IntersectionObserver(([entry]) => {
              if (entry.isIntersecting && this.hasMore && !this.isLoading) {
                  this.loadMore();
              }
          }, options);

          if (this.$refs.sentinel) {
              this.observer.observe(this.$refs.sentinel);
          }
      },
  },
};
</script>
<style>
.api-data-loader {
    position: relative;
}
</style>
