<template>
    <v-list-item v-if="isLinkedEvents">
        <v-list-item-action class="ml-4">
        </v-list-item-action>
        <v-flex v-for="(linkedEvent, i) in eventToEvents" :key="i" xs3>
            <linked-event-icon :event="linkedEvent.sourceEvent" :size="30"></linked-event-icon>
        </v-flex>
    </v-list-item>
</template>
<script>
import {mapGetters} from "vuex";
import LinkedEventIcon from "@/components/panel/sidebar/event/LinkedEventIcon.vue";

export default {
    name: 'linkedEventsHeadBar',
    components: {LinkedEventIcon},
    props: {
        event: Object,
    },
    computed: {
        ...mapGetters('eventToEvent', {
            byTargetEventId: 'byTargetEventId',
        }),
        isLinkedEvents() {
            return this.event.eventCategoryId === 17 && this.eventToEvents.length > 0;
        },
        eventToEvents() {
            return this.byTargetEventId(this.event.id);
        },
    }
}
</script>