<template>
  <div class="calendar fill-sidebar-height">
    <!--Calendar-->
    <div class="d-flex flex-column fill-sidebar">
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col cols="2">
            <v-btn x-large block dark color="grey darken-3" tile depressed @click="prev">
              <v-icon>fa-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8">
            <v-menu
                ref="menu"
                v-model="menu"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" x-large block dark color="grey darken-3" tile depressed @click="menu = true">
                  <span>{{ formatDate }}</span>
                </v-btn>
              </template>
              <v-date-picker v-model="date" no-title scrollable>
                <v-btn @click="today">Dnes</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-btn x-large block dark color="grey darken-3" tile depressed @click="next">
              <v-icon>fa-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <div class="grey darken-3 d-flex justify-center">
          <v-text-field v-model="search"
                        flat
                        hide-details
                        prepend-inner-icon="search"
                        placeholder="..."
                        solo
                        dark
                        background-color="grey darken-3"
                        dense
                        clearable
          >
          </v-text-field>
      </div>
      <!--Events -->
      <v-card tile class="event-list white elevation-0 tab-overflow" :loading="loading">
        <v-list>
            <v-lazy v-for="(event) in events" :key="event.uuid" min-height="40" class="flex-shrink-0">
                <event-head @onSelect="showDetail(event)" v-bind:event="event"></event-head>
            </v-lazy>
        </v-list>
        <v-skeleton-loader v-show="loading" class="ma-3"
                           type="list-item-avatar-three-line, divider, list-item-avatar-three-line"></v-skeleton-loader>
      </v-card>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import {mapActions, mapState} from "vuex";
import eventHead from "./event/HeadInactive";
import {SHOW_EVENT_VIEW} from "@/events";
import {appBus} from "@/main";
import eventsApi from "@/api/events";
import {pickBy, orderBy, chain, deburr} from "lodash";

export default {
  name: 'calendar',
  components: {
    eventHead,
  },
  data() {
    return {
      items: {},
      loading: false,
      menu: false,
      dialog: false,
      eventDialogKey: null,
    }
  },
  created() {
    this.getItems();
  },
  computed: {
    ...mapState({
      date: state => state.panel.date,
    }),
    events() {
        let filteredItems = this.items;
         if (this.search) {
             filteredItems =  pickBy(this.items, (item) => {
                 const combinedText = chain(item.header)
                     .map('value')
                     .map(deburr)
                     .filter(Boolean)
                     .join(' ')
                     .value()
                     .toLowerCase();

                 return combinedText.includes(deburr(this.search.toLowerCase()));
             })
         }
        return orderBy(filteredItems, ['start', 'id'], ['desc', 'desc'])
    },
    formatDate() {
      return moment(this.date).format('dddd D.M.YYYY');
    },
    search: {
      get() {
        return this.$store.state.calendar.search;
      },
      set(value) {
        this.$store.dispatch('calendar/setSearch', value)
      }
    },
    date: {
      get() {
        return this.$store.state.panel.date;
      },
      set(value) {
        this.$store.dispatch('panel/setDate', value)
      }
    },
  },
  methods: {
    ...mapActions('events', [
      'setActiveEvent',
    ]),
    getItems() {
      this.loading = true;
      this.items = {};
      eventsApi.getEventList({date: this.date}).then((response) => {
        this.loading = false;
        this.items = response;
      });
    },
    showDetail(event) {
      appBus.$emit(SHOW_EVENT_VIEW, event);
    },
    today() {
      this.date = moment().format(moment.HTML5_FMT.DATE);
    },
    next() {
      this.date = moment(this.date).add(1, 'd').format(moment.HTML5_FMT.DATE);
    },
    prev() {
      this.date = moment(this.date).subtract(1, 'd').format(moment.HTML5_FMT.DATE);
    },
  },
  watch: {
    date() {
      this.getItems();
    },
  },
}
</script>
