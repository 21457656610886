import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import createMutationsSharer from "vuex-shared-mutations";
import Cookies from 'js-cookie'
import auth from './modules/auth'

import abilityPlugin from './modules/ability'

import panel from './modules/panel'
import tags from './modules/tags'
import eventTypes from './modules/eventTypes'
import eventCategories from './modules/eventCategories'
import eventToEvent from './modules/event/eventToEvent'
import eventTerrains from './modules/eventTerrains'
import messageTypes from './modules/messageTypes'
import contactTypes from './modules/contactTypes'
import messages, {registerModule as registerMessages} from './modules/messages'
import devices from './modules/devices'
import bases from './modules/bases'
// import groups from './modules/groups'
// import users from './modules/users'
import events from './modules/events'
import eventJobs from './modules/event/jobs'
// import eventAccesses from './modules/event/accesses'
import eventContacts from './modules/event/contacts'
import eventPoises from './modules/event/poises'
import geo from '../components/geo/store'
import eventAlerts from './modules/eventAlerts'
import eventRoles from './modules/eventRoles'
import calendar from './modules/calendar'
import setup from './modules/setup'
import settings from './modules/settings'
import resources from './modules/event/resources'
import resourceTypes from './modules/resourceTypes'
import app from './modules/app'
import routes from "./modules/routes";
import notifications from "./modules/notifications";
import priorities from "./modules/priorities";
import sap from "../components/sap/store";
import explore from "../components/explore/store";
import deviceList from "../components/devicesList/store";
import eventDestination from "../components/eventDestination/store";
import { Ability } from '@casl/ability'

// Services
import services from "./modules/services";

// Map
import panorama from "./modules/map/panorama";
import cadastre from "./modules/map/cadastre";
import railImages from "./modules/map/railImages";
import map from './modules/map/map'
import mapLayers from './modules/map/mapLayers'
import huntingRegions from "../components/map/huntingRegions/store";
import trainLocation from "../components/map/trainLocation/store";

Vue.use(Vuex);

const vuexCookie = new VuexPersistence({
    restoreState: function (key) {
        return Cookies.getJSON(key);
    },
    saveState: function (key, state) {
        return Cookies.set(key, state, {
            expires: 3
        });
    },
    modules: ['auth'],
});

const vuexLocal = new VuexPersistence({
    key: 'panel',
    storage: window.localStorage,
    modules: ['setup', 'panel'],
});

const store = new Vuex.Store({
    modules: {
        setup,
        settings,
        auth,
        panel,
        map,
        mapLayers,
        bases,
        priorities,
        // groups,
        // users,
        tags,
        events,
        eventJobs,
        // eventAccesses,
        eventTypes,
        eventCategories,
        eventToEvent,
        eventRoles,
        eventTerrains,
        eventContacts,
        eventPoises,
        messageTypes,
        geo,
        eventAlerts,
        contactTypes,
        services,
        resources,
        resourceTypes,
        calendar,
        messages,
        devices,
        app,
        notifications,
        routes,
        panorama,
        cadastre,
        railImages,
        sap,
        explore,
        deviceList,
        eventDestination,
        huntingRegions,
        trainLocation,
    },
    state: {
        panelTitle: 'Tereza Panel',
        isMobile: false,
        errors: [],
        rules: [],
        pubNubStatus: Object,
        tereza: {
            apiUrl: process.env.VUE_APP_TEREZA_API_URL,
            apiSearchUrl: process.env.VUE_APP_TEREZA_API_URL + '/v2/search/query',
            apiReverseGeoUrl: process.env.VUE_APP_TEREZA_API_URL + '/v2/search/reverse',
            proxyUrl: process.env.VUE_APP_TEREZA_API_URL + '/proxy.php',
            featureUrl: process.env.VUE_APP_TEREZA_API_URL + '/feature-proxy.php',
            imageProxyUrl: process.env.VUE_APP_TEREZA_API_URL + '/image-proxy.php?url=',
        }
    },
    plugins: [
        vuexCookie.plugin,
        vuexLocal.plugin,
        createMutationsSharer({predicate: ["auth/resetState", "auth/setUser", "auth/logout", 'messages/setNew']}),
        abilityPlugin
    ],
    getters: {
        ability() {
            return new Ability()
        },
        isOnline: state => {
            let status = '';
            switch (state.pubNubStatus.category) {
                case 'PNNetworkUpCategory':
                    status = "Připojuje se";
                    break;
                case 'PNNetworkDownCategory':
                    status = "Odpojeno";
                    break;
                case 'PNNetworkIssuesCategory':
                    status = "Connection error";
                    break;
                case 'PNReconnectedCategory':
                    status = "Připojeno";
                    break;
                case 'PNConnectedCategory':
                    status = "Připojeno";
                    break;
                case 'PNAccessDeniedCategory':
                    status = "Connection denied";
                    break;
                case 'PNMalformedResponseCategory':
                    status = "Parsing crashed";
                    break;
                case 'PNBadRequestCategory':
                    status = "Request is malformed";
                    break;
                case 'PNDecryptionErrorCategory':
                    status = "Decryption fails";
                    break;
                case 'PNTimeoutCategory':
                    status = "Connection timeout";
                    break;
                case 'PNRequestMessageCountExceedCategory':
                    status = "Message Count Exceed";
                    break;
                case 'PNUnknownCategory':
                    status = "Connection category error";
                    break;
                default:
                    status = "Unknown status";
                    break;
            }
            return status;
        },
        pubSubStatus: state => {
            return (key) => {
                return state.pubNubStatus[key];
            }
        },
    },
    mutations: {
        setPubSubStatus(state, status) {
            state.pubNubStatus = status;
        },
        setIsMobile(state, value) {
            state.isMobile = value;
        },
        logError(state, err) {
            state.errors.push(err.message);
        },
        clearErrors(state) {
            state.errors = [];
        }
    },
})

registerMessages(store);

export default store; 
