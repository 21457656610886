import {stateMerge} from "../../object-merge";
import {find} from "lodash";

const getDefaultState = () => {
    return {
        items: {},
        icons: {
            'big-truck': 'r',
            'bus': 's',
            'car': 't',
            'crane': 'u',
            'fire-truck': 'v',
            'pickup': 'z',
            'platform': 'A',
            'tractor': 'B',
            'truck': 'C',
        }
    }
};

const state = getDefaultState();

const getters = {
    byId: state => (id) => {
        if (state.items[id] !== undefined)
            return state.items[id];
        return null;
    },
    byEventJobId: state => (eventJobId) => {
        let item = find(state.items, {eventJobUi: eventJobId});
        if (item === undefined)
            return false;
        return item;
    },
};

const actions = {
    async set({commit}, items) {
        if (items)
            commit("MERGE_STATE", items)
    },
};

const mutations = {
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'items', false, true)
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
