import {Ability, AbilityBuilder} from '@casl/ability';
import {forEach, includes} from "lodash";

export default (store) => {
    const ability = store.getters.ability

    ability.update(store.state.rules)

    const setPermissions = () => {

        const {can, rules} = new AbilityBuilder(Ability);
        setUserPermissions(can)
        setEventPermissions(can)
        ability.update(rules)
        console.log('CASL aktuální pravidla:', ability.rules)
    }

    const setUserPermissions = (can) => {

        const roles = store.state.auth.user.roles;
        const groups = store.state.auth.user.groups;
        const permissions = store.state.auth.permissions;

        if (includes(roles, 'admin')) {
            can('manage', 'Debug');
        }
        if (includes(groups, 'MNG')) {
            can('view', 'Event');
            can('view', 'EventChild');

            can('view', 'Message');
            can('view', 'Message2');
            can('view', 'Message3');

        } else if (includes(roles, 'udrp')) {
            can('manage', 'Message');
            // can('manage', 'Message3');

        } else {
            can('manage', 'Device');
            can('view', 'Event');
            can('manage', 'Message');
            can('view', 'EventChild');
            can('view', 'EventResources');
        }

        can('manage', 'Message5');
        can('view', 'EventContact');

        forEach(permissions, (permission, ) => {
            const [subject, action] = permission.split('.')
            if (action && subject) {
                can(action, subject)
            }
        })
    }

    function setEventPermissions(can) {

        const activeEvent = store.getters['events/getActiveEvent']
        if (!activeEvent) {
            can('manage', 'Message2');
            can('manage', 'Message3');
            return;
        }

        forEach(activeEvent?.messageCategories, (value, key) => {
            can('manage', 'Message' + key);
        })

        forEach(activeEvent.eventRoles, (roleId) => {
            const role = store.getters['eventRoles/get'](roleId)
            if (role && role.permissions) {
                forEach(role.permissions, (permission) => {
                    const [subject, action] = permission.split('.')
                    if (action && subject) {
                        can(action, subject)
                    }
                })
            }
        })
    }

    const unsetPermissions = () => {
        ability.update([{actions: 'read', subject: 'all'}])
    }

    return store.subscribe((mutation) => {
        switch (mutation.type) {
            case 'auth/setIdentity':
                setPermissions()

                break
            case 'auth/logout':
                unsetPermissions()
                break

            case 'events/setActive':
                setPermissions()
                break

            case 'events/new':
                setPermissions()
                break

            case 'events/update': {
                const activeEventKey = store.state.events.active
                const { key } = mutation.payload;
                if (activeEventKey === key)
                    setPermissions()
                break
            }

            case 'auth/MERGE_STATE':
                setPermissions()
                break
        }
    })
}
