import forEach from "lodash/forEach";
import orderBy from "lodash/orderBy";
import findKey from "lodash/findKey";
import toNumber from "lodash/toNumber";
import {stateMerge} from "../object-merge";

const getDefaultState = () => {
    return {
        items: {},
    }
};

const state = getDefaultState();

const getters = {
    shiftCategoryId: state => {
        return toNumber(findKey(state.items, function (o) {
            return o.shift === 1;
        }));
    },
    getEventCategoryById: (state) => {
        return (id) => {
            return state.items[id];
        }
    },
    toggleOptions: (state, getters, rootState) => (id) => {

        const eventCategory = getters.getEventCategoryById(id)
        const ids = getters.getEventCategoryById(id).toggleCategoryIds;
        const baseId = rootState.auth.user.baseId;
        const options = [];

        options.push({
            value: Number(eventCategory.id),
            text: eventCategory.name,
            color: eventCategory.color,
            sort: eventCategory.sort,
            icon: eventCategory.icon,
        });

        if (!ids)
            return options;


        forEach(state.items, (item, id) => {
            if (Object.prototype.hasOwnProperty.call(ids, id)) {

                if ((Number(id) === 14 || Number(id) === 15) && baseId !== 2)
                    return;

                options.push({
                    value: Number(id),
                    text: item.name,
                    color: item.color,
                    sort: item.sort,
                    icon: item.icon,
                });
            }
        });
        return orderBy(options, ['sort'], ['asc']);
    },

    investigatorOptions: (state, getter, rootState) => () => {
        let options = [];
        options.push({ header: 'Skupiny' });
        forEach(rootState.groups.items, (item) => {
            options.push({
                id: item.id,
                uid: 'g' + item.id,
                type: 'group',
                title: item.name,
            });
        });
        options.push({ header: 'Uživatelé' });
        forEach(rootState.users.items, (item) => {
            options.push({
                id: item.id,
                uid: 'u' + item.id,
                type: 'user',
                title: item.name,
            });
        });

        return options;
    },

    options: state => {
        let options = [];
        forEach(state.items, (item, id) => {
            options.push({
                value: Number(id),
                text: item.name,
            });
        });
        return options;
    },
    sectionTitle: (state) => (category, input) => {
        if (Number.isInteger(category))
            category = state.items[category];
        return category.inputs[input] !== undefined ? category.inputs[input].title : null
    },
};

const actions = {
    async set({commit}, items) {
        if (items)
            commit("MERGE_STATE", items)
    },
};

const mutations = {
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'items', false, true)
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
