import MarkdownIt from 'markdown-it'
import markdownItCollapsible from 'markdown-it-collapsible'

const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
})

mdParser.use(markdownItCollapsible)

export function renderMarkdown(text) {
    if (!text || typeof text !== 'string') {
        return ''
    }
    return mdParser.render(text)
}

export function isMarkdown(text) {
    if (!text || typeof text !== 'string') {
        return false
    }
    const trimmedText = text.trim()

    return !trimmedText.toLowerCase().startsWith('<table');
}
