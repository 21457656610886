<template>
    <div>
        <v-icon :size="size" :color="color">
            {{ icon }}
        </v-icon>
        {{ label }}
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'linked-event-icon',
    props: {
        event: {
            type: Object,
            required: true,
        },
        grayscale: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Number,
            default: 40,
        }
    },
    computed: {
        ...mapGetters('eventCategories', {
            getEventCategoryById: 'getEventCategoryById',
        }),
        eventCategory() {
            return this.getEventCategoryById(this.event.eventCategoryId);
        },
        label() {
            return this.eventCategory.codeShortName
        },
        icon() {
            return this.eventCategory.icon
        },
        color() {
            if (this.grayscale)
                return "#666";
            return this.eventCategory.color;
        }
    },
}
</script>
